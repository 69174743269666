(function($) {

/*
Functions
*/ 

var currentWindowSize = $(window).width();

function checkDefiniteResize(){
  	var definiteResize = false;
  	if(currentWindowSize !== $(window).width()){
    	definiteResize = true;
  	}

  	currentWindowSize = $(window).width();
  	return definiteResize;
}

function getWindowWidth(){

    var bodyOverflowX = $('body').css('overflow-x');
    var bodyOverflowY = $('body').css('overflow-y');
    $('body').css('overflow', 'hidden');
    var windowWidth = $(window).width();
    $('body').css({
        'overflowX': bodyOverflowX,
        'overflowY': bodyOverflowY
    });

    return windowWidth;
}

function matchHeight(windowWidth, holders, resizeClasses, callback){

    $(holders).each(function(){

        var holder = $(this);
        var resize = [];

        $.each(resizeClasses, function(i, val) {
        	// Create an array of the elements that are to be resized
        	resizeSingle = [];
        	resizeSingle[0] = val;
        	resizeSingle[1] = 0;
        	resize[i] = resizeSingle;
        });

        $.each(resize, function(i) {
        	holder.find(resize[i][0]).css('height', 'auto');
        });

        if(windowWidth > holder.attr('data-min-width')){ 
            holder.children('.row').each(function(){

                var rowElement = $(this);
                var indexes = [];
                var clearfixEnd = false;

                $.each(resize, function(i) {
                	resize[i][1] = 0;
                });

                rowElement.children().each(function(){

                	var childElement = $(this);

                    if(childElement.is(':visible')){

                        if(childElement.hasClass('clearfix')){

                            $.each(indexes, function(i, val){
				                $.each(resize, function(i2) {
				                	rowElement.children().eq(val).find(resize[i2][0]).height(resize[i2][1]);
				                });
                            });

                            indexes = [];
                            clearfixEnd = true;

			                $.each(resize, function(i) {
			                	resize[i][1] = 0;
			                });

                        }else{
                            indexes.push($(this).index());
                            clearfixEnd = false;

			                $.each(resize, function(i) {
	                            if(childElement.find(resize[i][0]).height() > resize[i][1]){
	                            	resize[i][1] = childElement.find(resize[i][0]).height();
	                            }
			                });
                        }
                    }

                });

                if(!clearfixEnd){
                    $.each(indexes, function(i, val){
		                $.each(resize, function(i2) {
		                	rowElement.children().eq(val).find(resize[i2][0]).height(resize[i2][1]);
		                });
                    }); 
                }
            });
        }
    });
	
	if(callback !== false){
		callback();
	}
	
	$(holders).css('opacity', 1);
}

function matchHeights(){

    // Match the heights of certain element's child elements, once they have been resized change the opacity to 1 (the elements should initially be hidden through CSS)
    matchHeight(getWindowWidth(), 'header > .top', ['> .inner'], false);
    matchHeight(getWindowWidth(), '.page-template-template-home .sections .section > .inner', ['.match-height'], false);
    matchHeight(getWindowWidth(), '#nomination-deadline-holder', ['.match-height'], false);
    matchHeight(getWindowWidth(), '#login-register-holder', ['.match-height'], false);
    matchHeight(getWindowWidth(), '.private-leagues', ['.match-height'], false);
    matchHeight(getWindowWidth(), '.weekly-nominations .categories', ['.title', '.match-height'], false);
}

function create_divided_headers() {
	$('h1, h2, h3, h4, h5, h6').each(function() {
		if( $(this).hasClass( 'divider' ) ) {
			$(this).wrapInner('<span></span>');
		}
	});
}

function format_number_for_countdown(countdown, type) {

    var $element = $('.countdown-holder .countdown').find('.' + type);
    var value = countdown[type];

    if( type == 'minutes' && value < 20 && countdown['days'] == 0 && countdown['hours'] == 0 ) {
        $element.addClass('warning').parents('.number-holder').siblings('.number-holder[data-type="seconds"]').find('.number').addClass('warning');
    }

    if( type == 'days' && value == 0 ||
        type == 'hours' && value == 0 && countdown['days'] == 0 ||
        type == 'minutes' && value == 0 && countdown['hours'] == 0 && countdown['days'] == 0 ||
        type == 'seconds' && value == 0 && countdown['minutes'] == 0 && countdown['hours'] == 0 && countdown['days'] == 0) {
            $element.addClass('disabled');
    }

    $element.text(value.toString().length == 1 ? '0' + value : value);
}

function update_countdown( deadline, daylight_savings ) {

    if( daylight_savings == 1 ) {
        var current_time = Math.floor(new Date().getTime() / 1000) + 1*60*60;
    }else {
        var current_time = Math.floor(new Date().getTime() / 1000);
    }

    var countdown = {};
    var difference = (deadline - current_time > 0 ? deadline - current_time : 0);

    countdown.days = Math.floor(difference / 86400);
    difference -= countdown.days * 86400;

    countdown.hours = Math.floor(difference / 3600) % 24;
    difference -= countdown.hours * 3600;

    countdown.minutes = Math.floor(difference / 60) % 60;
    difference -= countdown.minutes * 60;

    countdown.seconds = difference % 60;

    // console.log( days + ' ' + hours + ' ' + minutes + ' ' + seconds );
    format_number_for_countdown(countdown, 'days');
    format_number_for_countdown(countdown, 'hours');
    format_number_for_countdown(countdown, 'minutes');
    format_number_for_countdown(countdown, 'seconds');

    if(Math.abs(deadline - current_time) > 0) {
        setTimeout(function(){
            update_countdown( deadline, daylight_savings );
        }, 100);
    }
}

function changeLeagueWeekToggleAllButton( week ) {
    var toggle_button = week.find('.toggle-all-holder .toggle-all');
    var closed = week.find('.user-details[data-open="0"]').length;

    if(closed == 0) {
        toggle_button.text(toggle_button.attr('data-close-text'));
        toggle_button.attr('data-all-open', 1);
    }else{
        toggle_button.text(toggle_button.attr('data-open-text'));
        toggle_button.attr('data-all-open', 0);
    }
}

function onReadyResponsive(initial, windowWidth, definiteResize) {

	// Remove the bottom margin from last items in grids
	$('.remove-last-margin').each(function(){

		var row = $(this);
		row.find('> div:not(.clearfix)').css('margin-bottom', '');

		var grid = false;
		row.find('> div:not(.clearfix)').each(function(){
			if($(this).outerWidth(false) != row.width()){
				grid = true;
			}
		});

		var elements;

		if(grid){

			if(!row.find('> .clearfix:visible').last().nextAll('div:not(.clearfix)').length){

				if($(this).find('> .clearfix:visible').last().prevAll('.clearfix:visible').length){
					elements = row.find('> .clearfix:visible').last().prevAll('.clearfix:visible').first().nextAll('div:not(.clearfix)');
				}else{
					elements = row.find('> div:not(.clearfix)');
				}

			}else{ 
				// If there are elements after the last visible clearfix then remove their bottom margins
				elements = row.find('> .clearfix:visible').last().nextAll('div:not(.clearfix)');
			}
		}else{ 
			// If any of the items are full width then only remove the bottom margin from the last one as we are not in a grid
			elements = row.find('> div:not(.clearfix)').last();
		}

		elements.css('margin-bottom', 0);
	});

    $('.custom-select').selectmenu('refresh');

    if($('body').hasClass('login')) {
        // Stick the footer to the bottom of the page
        if( ( $('header').height() + $('.wrap').outerHeight() ) < ( $(window).height() - $('footer').outerHeight() ) ) {
            $('footer').css({
                'position': 'absolute',
                'bottom': 0
            });
        }else{
            $('footer').css({
                'position': 'static'
            }); 
        }
    }

    // Move homepage sections around
    $('.page-template-template-home .sections .section[data-style="2"]').each(function() {
        if( windowWidth <= 991 ) {
            $(this).find('.text-holder').insertAfter($(this).find('.image-holder'));
        }else{
            $(this).find('.image-holder').insertAfter($(this).find('.text-holder'));
        }
    });
}

function onLoadResponsive(initial, windowWidth, definiteResize) {

    // Pad out the main menu
    var menu_items_num = $('header .menu-holder .nav-primary li').length;
    var menu_space = $('header .menu-holder').width();
    $('header .menu-holder .nav-primary li > a').each(function(){
        menu_space -= $(this).width();
    });
    var menu_items_margin = Math.floor(menu_space / menu_items_num) - 1;
    $('header .menu-holder .nav-primary li').css({
        'padding-left': menu_items_margin / 2,
        'padding-right': menu_items_margin / 2,
        'margin-left': 0
    });

    // Make sure that the login screen is 100% of the screen
    $('body.login > .body-inner').css( 'min-height', $(window).height() );

	matchHeights();

    if( definiteResize ) {
        if( $('header > .bottom').length ) {

            var bottom_padding = ( ( $('header').height() - $('header > .top').outerHeight() ) - $('header > .bottom').innerHeight() ) / 2;

            $('header > .bottom > .inner').css({
                'padding-top': bottom_padding,
                'padding-bottom': bottom_padding,
            });
        }
    }
}

/*
jQuery events
*/ 

$(document).ready(function() {

    // Toggle mobile menu
    $('header > .top .menu-toggle > .inner').click(function() {
        $(this).parent().toggleClass('active');
        $('header > .top').toggleClass('menu-shown');
        $('header .mobile-menu-holder > .inner').slideToggle();
    });

    if($('.countdown-holder .countdown').length) {
        // update_countdown(parseInt($('.countdown-holder .countdown').attr('data-deadline')), parseInt($('.countdown-holder .countdown').attr('data-current-time')));
        update_countdown(parseInt($('.countdown-holder .countdown').attr('data-deadline')), $('.countdown-holder .countdown').attr('data-daylight-savings'));
    }

    // Hide the normal file uploader and replace with a new custom file upload button
    $('.custom-file').each(function(){
        $(this).wrap('<div class="hide-normal"></div>');
        $(this).parents('.hide-normal').hide();
        $(this).parents('.hide-normal').after('<div class="file-upload-mask"><div class="attach btn btn-primary">Add a file</div><div class="filename">&nbsp;</div><div class="clearfix"></div></div>');
    });

    // Configure any custom file upload masks so that the 'hidden' html file input is trigged when the custom mask is clicked
    $(document).on("click", '.file-upload-mask .attach', function(){
        $(this).parents('.file-upload-mask').siblings('.hide-normal').find('input[type=file]').trigger('click'); 
    });

    // When a file is added get the filename and add it to the custom mask
    $('input[type=file]').change(function() {

        if($(this).parents('.hide-normal').length){
            var filename = $(this).val().split('\\').pop();
            $(this).parents('.hide-normal').siblings('.file-upload-mask').find('.filename').text(filename);
            $(this).parents('.hide-normal').parent().find('.form-error').show();
        }
    });

    // Toggle league weeks
    $('.league-rankings-section[data-open="1"] > .rankings').show();
    $('.league-rankings-section > .title .toggle').click(function() {
        var week = $(this).parents('.league-rankings-section');
        var current_status = week.attr('data-open');

        if ( current_status == 1 ) {
            week.find('.rankings').slideUp();
        } else {
            week.find('.rankings').slideDown();
        }

        week.attr( 'data-open', current_status == 1 ? 0 : 1 );
    });

    // Set highlighted league user rows
    $('.league-rankings-section > .rankings .user-details.highlight').each(function(){
        $(this).prev().addClass('sibling-open');
    });

    // Toggle league week user data
    $('.league-rankings-section > .rankings .user-details > .top .toggle').click(function() {
        var user = $(this).parents('.user-details');
        var week = $(this).parents('.league-rankings-section');
        var current_status = user.attr('data-open');

        if ( current_status == 1 ) {
            user.find('> .bottom').slideUp();
        } else {
            user.find('> .bottom').slideDown();
        }

        user.attr( 'data-open', current_status == 1 ? 0 : 1 );
        user.prev().toggleClass('sibling-open');

        changeLeagueWeekToggleAllButton(week);
    });

    // Open all league week user data
    $('.league-rankings-section > .rankings > .labels .toggle-all').click(function(e) {
        e.preventDefault();

        var week = $(this).parents('.league-rankings-section');
        var current_status = $(this).attr('data-all-open');

        if ( current_status == 1 ) {
            week.find('.rankings .user-details').removeClass('sibling-open').attr( 'data-open', 0 ).find('> .bottom').slideUp();
        }else{
            week.find('.rankings .user-details').addClass('sibling-open').attr( 'data-open', 1 ).find('> .bottom').slideDown();
        }

        changeLeagueWeekToggleAllButton(week);
    });

    // Custom selects
    $('.custom-select').selectmenu({
        width: null
    }).addClass('overflow');
    
    // Show a confimation overlay when the user clicks to remove a player from their league
    $('.league-rankings-section .user-details .remove-player').click(function(e) {
        e.preventDefault();

        var remove_link = $(this).attr('href');
        var username = $(this).parents('.user-details').find('.avatar-and-name .name').text();

        var $overlay = $('<div><div class="inner"></div></div>').attr('class', 'fbo-overlay confirm-league-user-block').height($(document).height());
        var overlay_inner = $overlay.find('> .inner');

        var overlay_content = '<h2>Are you sure you want to block ' + username + '?</h2>';
        overlay_content += '<button type="button" class="btn btn-danger yes">Yes</button>';
        overlay_content += '<button type="button" class="btn btn-primary btn-bordered no">No</button>';

        overlay_inner.html(overlay_content);
        $('body').append($overlay);

        overlay_inner.css({
            'top': ( (overlay_inner.innerHeight() + 20) >= $(window).height() ? 20 : (($(window).height() - overlay_inner.innerHeight()) / 2) ),
            'left': ( (overlay_inner.innerWidth() + 20) >= $(window).width() ? 20 : (($(window).width() - overlay_inner.innerWidth()) / 2) ),
            'max-height': $(window).height() - 40,
            'max-width': $(window).width() - 40
        });

        $overlay.addClass('shown');

        $overlay.find('.no').click(function(){
            $overlay.removeClass('shown');
            setTimeout(function(){
                $overlay.remove();
            }, 400);
        });

        $overlay.find('.yes').click(function(){
            window.location = remove_link;
        });

    }); 

    onReadyResponsive(true, getWindowWidth(), true);
});

$(window).load(function(){

	create_divided_headers();

 	onLoadResponsive(true, getWindowWidth(), true);
 	$('.initial-hidden').css('opacity', 1);
});

$(window).resize(function() {

	var definiteResize = checkDefiniteResize();
  	var windowWidth = getWindowWidth();

	onLoadResponsive(false, windowWidth, definiteResize);
	onReadyResponsive(false, windowWidth, definiteResize);
});

})(jQuery); // Fully reference jQuery after this point.
